<template>
  <!-- Error page-->
  <div class="misc-wrapper">
    <b-link class="brand-logo">
      <gameguru-logo />
      <h2 class="brand-text text-primary ml-1">
        Gameguru Store
      </h2>
    </b-link>

    <div class="misc-inner p-2 p-sm-3">
      <div class="w-100 text-center">
        <h2 class="mb-1">
          Under Maintenance 🛠
        </h2>

        <p class="mb-3">
          Sorry for the inconvenience but we're performing some maintenance at the moment
        </p>
        <b-img
          fluid
          :src="imgUrl"
          alt="Under maintenance page"
        />
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { BLink, BButton, BImg } from 'bootstrap-vue'
import GameguruLogo from '@/components/GameguruLogo.vue'
import store from '@/store/index'
import router from '@/router'

export default {
  components: {
    GameguruLogo,
    BLink,
    BButton,
    BImg,
  },
  data() {
    return {
      downImg: require('@/assets/images/maintenance0.gif'),
    }
  },
  computed: {
    imgUrl() {
      this.downImg = require(`@/assets/images/maintenance${Math.floor(Math.random() * 3)}.gif`)

      return this.downImg
    },
  },
  mounted() {
    this.maintenanceControl()
  },
  methods: {
    maintenanceControl() {
      this.$store.dispatch('settingStore/fetchMaintenance').then(response => {
        const { data } = response
        if (!data.data) {
          router.push({ name: 'build-list' })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-misc.scss';
</style>
